import request from '@/utils/request'


// 查询用户实名认证列表
export function listRealAuth(query) {
  return request({
    url: '/user/user-real-auths/list',
    method: 'get',
    params: query
  })
}

// 查询用户实名认证分页
export function pageRealAuth(query) {
  return request({
    url: '/user/user-real-auths/page',
    method: 'get',
    params: query
  })
}

// 查询用户实名认证详细
export function getRealAuth(data) {
  return request({
    url: '/user/user-real-auths/detail',
    method: 'get',
    params: data
  })
}

// 新增用户实名认证
export function addRealAuth(data) {
  return request({
    url: '/user/user-real-auths/add',
    method: 'post',
    data: data
  })
}

// 修改用户实名认证
export function updateRealAuth(data) {
  return request({
    url: '/user/user-real-auths/edit',
    method: 'post',
    data: data
  })
}

// 删除用户实名认证
export function delRealAuth(data) {
  return request({
    url: '/user/user-real-auths/delete',
    method: 'post',
    data: data
  })
}
export function batchApprove(data) {
  return request({
    url: '/user/user-real-auths/batchApprove',
    method: 'post',
    data: data
  })
}
export function approveRealAuth(data) {
  return request({
    url: '/user/user-real-auths/approveRealAuth',
    method: 'post',
    data: data
  })
}

